import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import SampleTravelPlan from "../Container/Dashboard/Traveler/SampleTravelPlan"
import { GlobalStateProvider } from "../State/global-state/global-state"
import "./mystyles.scss"

const ViewSampleTravelPlanBase = () => (
  <Layout>
    <SEO
      pageTitle="Sample Travel Plan"
      description="View our sample travel plan"
      title="voyayge.com"
    />
    <SampleTravelPlan />
  </Layout>
)
const ViewSampleTravelPlan = () => {
  return (
    <GlobalStateProvider>
      <ViewSampleTravelPlanBase />
    </GlobalStateProvider>
  )
}
export default ViewSampleTravelPlan
