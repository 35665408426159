import React, { useState } from "react"
import "./Accordion.scss"
import ReactHtmlParser from "react-html-parser"
export default function Accordion({ header, data, fontSize = "18px" }) {
  const [expand, setExpand] = useState(false)
  return (
    <article className="message">
      <div
        className="message-header is-warning"
        onClick={() => {
          setExpand(!expand)
        }}
        style={{ cursor: "pointer" }}
      >
        {expand ? (
          <>
            <i className="far fa-chevron-down" style={{ color: "#fec600" }}></i>
            <p
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: "15px",
                fontSize: fontSize,
                fontWeight: "600",
                color: "#fec600",
              }}
            >
              {header}
            </p>
          </>
        ) : (
          <>
            <i
              className="far fa-chevron-right"
              style={{ color: "#fec600" }}
            ></i>
            <p
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: "15px",
                fontSize: fontSize,
                fontWeight: "600",
              }}
            >
              {header}
            </p>
          </>
        )}

        {/* <div></div> */}
      </div>
      {expand && (
        <div
          className="message-body is-collapsible"
          data-parent="accordion_first"
        >
          <div
            className="message-body-content"
            style={{
              textAlign: "left",
              width: "100%",
              // marginLeft: "25px",
              marginTop: "0px",
              fontSize: "16px",
              lineHeight: "1.8",
            }}
          >
            {ReactHtmlParser(data)}
          </div>
        </div>
      )}
    </article>
  )
}
